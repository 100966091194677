import type { NavigationGuard } from '#vue-router'

export const storePreviousRoute: NavigationGuard = (to, from) => {
  if (from.name
    && !from.name.toString().startsWith('settings-')
    && !from.name.toString().startsWith('teamspace-new')
    && !to.query.returnRoute
  ) {
    return navigateTo({
      ...to,
      query: { ...to.query, returnRoute: from.fullPath },
    })
  }
}

export const persistReturnRoute: NavigationGuard = (to, from) => {
  if (from.query.returnRoute && !to.query.returnRoute) {
    return navigateTo({
      ...to,
      query: { ...to.query, returnRoute: from.query.returnRoute },
    })
  }
}

export const redirectToFirstChild: NavigationGuard = (to) => {
  if (to.name === 'settings-organization') {
    return navigateTo({ name: 'settings-organization-users' })
  }
  else if (to.name === 'settings-teamspace-teamspaceId') {
    return navigateTo({
      name: 'settings-teamspace-teamspaceId-general',
      params: { teamspaceId: to.params.teamspaceId.toString() },
    })
  }
  else if (to.name === 'settings-user') {
    return navigateTo({ name: 'settings-user-my' })
  }
  else if (to.name === 'teamspace-new') {
    return navigateTo({ name: 'teamspace-new-name' })
  }
}
