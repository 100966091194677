import { defineNuxtPlugin, useNuxtApp, useRuntimeConfig } from '#app'
import posthog from 'posthog-js'

// Source: https://posthog.com/docs/libraries/nuxt-js
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  let posthogClient
  if (runtimeConfig.public.posthogPublicKey) {
    posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
      api_host: runtimeConfig.public.posthogHost || 'https://app.posthog.com',
      capture_pageview: false, // pageview is tracked by Segment
      segment: useNuxtApp().$segment,

    // Uncomment to enable debugging in development mode
    // loaded: (posthog) => {
    //   if (import.meta.env.MODE === 'development')
    //     posthog.debug()
    // },
    })
  }
  else {
    posthogClient = null
  }

  return {
    provide: {
      posthog: () => posthogClient,
    },
  }
})
