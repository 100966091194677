import { defineNuxtPlugin } from '#app'
import * as Sentry from '@sentry/vue'
import { withScope } from '@sentry/vue'

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    const vueApp = nuxtApp.vueApp
    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      autoSessionTracking: true,
      dsn: config.public.sentryDsn,
      release: config.public.releaseCommitSha,
      environment: config.public.environment,
      integrations: [
        Sentry.browserTracingIntegration({ router: vueApp.$nuxt.$router }),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.replayIntegration(),
        // Comment out `thirdPartyErrorFilterIntegration` to test locally if Sentry is working
        Sentry.thirdPartyErrorFilterIntegration({
          // Specify the application keys that you specified in the Sentry bundler plugin
          filterKeys: ['pulse-app'],
          // Defines how to handle errors that contain third party stack frames.
          // Possible values are:
          // - 'drop-error-if-contains-third-party-frames'
          // - 'drop-error-if-exclusively-contains-third-party-frames'
          // - 'apply-tag-if-contains-third-party-frames'
          // - 'apply-tag-if-exclusively-contains-third-party-frames'
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    })

    vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context)
        // will be picked up by the `Sentry.captureConsoleIntegration`
        console.error(err)
      })
    }

    nuxtApp.hook('app:error', (err) => {
      if (err.statusCode === 404) {
        return
      }
      // will be picked up by the `Sentry.captureConsoleIntegration`
      console.error(err)
    })

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    }
  },
})
