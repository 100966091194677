<script setup lang="ts">
import type { RouteLocationNormalized } from 'vue-router'
import { VTour } from '@globalhive/vuejs-tour'
import { promiseTimeout } from '@vueuse/core'
import { getAllTours, Tours, waitForElement } from './apptour-metadata'
import { MarkTourAsSeenDocument } from './AppTour.generated'

interface TourStep {
  // basically extending ITourStep which is not exported
  target: string
  content: string
  // add our own properties
  title: string
  image?: string
}

const { enabled: toursEnabled } = useFlag('IN_APP_GUIDANCE')
const tour = useTemplateRef('tour')
const tourInProgress = ref(false)
const currentRoute = useRoute()
const { user, canChangeOrgConfig, isFetching: userIsFetching } = useCurrentUser()

const { execute: markTourAsSeen } = useMutation(MarkTourAsSeenDocument, {
  refetchTags: [`user`],
})

const startRoute = ref<RouteLocationNormalized | null>(null)
const allTours = computed(() => getAllTours(currentRoute, canChangeOrgConfig.value))

const currentTourName = ref<Tours | undefined>(undefined)
const currentTourSteps = computed(() => {
  if (!currentTourName.value)
    return []

  return allTours.value[currentTourName.value]
})

watch([currentRoute, toursEnabled, user], async ([route, flag, user]) => {
  if (!flag || !user || tourInProgress.value || userIsFetching.value) {
    return
  }

  if (route.name === 'teamspace-workspaceId-reviews'
    && !user?.seen.some(tour => tour.name === Tours.welcome)) {
    currentTourName.value = Tours.welcome
  }

  if (
    route.name === 'teamspace-workspaceId-reviews-reviewId'
    && route.query.newReview
    && !user?.seen.some(tour => tour.name === Tours.review)) {
    currentTourName.value = Tours.review
  }

  if (
    route.name === 'teamspace-workspaceId-surveys-surveyId'
    && route.query.newSurvey
    && !user?.seen.some(tour => tour.name === Tours.survey)) {
    currentTourName.value = Tours.survey
  }
})

watch([currentTourName, currentRoute], async () => {
  if (!currentTourName.value || tourInProgress.value)
    return
  await waitForElement(currentTourSteps.value[0].target)
  // Wait a bit before starting the tour
  promiseTimeout(1000)
  tour.value?.startTour()
})

function handleTourStart() {
  tourInProgress.value = true
  startRoute.value = {
    name: currentRoute.name,
    params: currentRoute.params,
  } as RouteLocationNormalized
}

async function handleTourEnd() {
  if (!currentTourName.value)
    return

  await markTourAsSeen({
    tourName: currentTourName.value,
  })

  tourInProgress.value = false
  currentTourName.value = undefined

  if (currentRoute.name !== startRoute.value?.name)
    await navigateTo(startRoute.value)
}
</script>

<template>
  <Teleport to="body">
    <VTour
      ref="tour"
      :steps="currentTourSteps"
      :name="currentTourName"
      backdrop
      highlight
      hide-arrow
      save-to-local-storage="never"
      @on-tour-start="handleTourStart"
      @on-tour-end="handleTourEnd"
    >
      <template #content="{ _CurrentStep }">
        <div class="mb-2 text-xs text-gray-500 dark:text-gray-400">
          {{ _CurrentStep.currentStep + 1 }} of {{ currentTourSteps.length }}
        </div>
        <h4 class="mb-2">
          {{ (_CurrentStep.getCurrentStep as TourStep)?.title }}
        </h4>
        <div v-html="_CurrentStep.getCurrentStep?.content" />
        <img
          v-if="(_CurrentStep.getCurrentStep as TourStep)?.image"
          :src="(_CurrentStep.getCurrentStep as TourStep)?.image"
          class="mt-4 border-8 border-gray-200"
        >
      </template>
      <template #actions="{ lastStep, nextStep, endTour, _CurrentStep, props }">
        <div class="mt-4 flex justify-end gap-2">
          <UButton
            v-if="_CurrentStep.currentStep < props.steps.length - 1"
            size="sm"
            color="gray"
            variant="ghost"
            class="mr-auto"
            @click="endTour()"
          >
            Skip
          </UButton>
          <UButton
            v-if="_CurrentStep.lastStep < _CurrentStep.currentStep"
            size="sm"
            icon="light:arrow-left"
            color="gray"
            @click="lastStep()"
          >
            Back
          </UButton>
          <UButton
            v-if="_CurrentStep.currentStep === props.steps.length - 1"
            size="sm"
            @click="nextStep()"
          >
            Finish
          </UButton>
          <UButton
            v-else
            size="sm"
            icon="light:arrow-right"
            trailing
            @click="nextStep()"
          >
            Next
          </UButton>
        </div>
      </template>
    </VTour>
    <div v-if="tourInProgress" class="fixed inset-0 z-[9997]" />
  </Teleport>
</template>

<style>
[data-hidden] {
  @apply hidden;
}

#vjt-backdrop {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-[9998] transition-all;
}

#vjt-tooltip {
  @apply absolute z-[9999] bg-gray-50 dark:bg-gray-800 p-4 rounded-lg text-base shadow-md max-w-96;
}

.vjt-highlight {
  @apply !outline-[3px] !outline !outline-blue-400 relative !opacity-100;
}
</style>
