let apiRootUrl = ''

async function validateResponse(response: Response): Promise<null | string> {
  const responseJson = await response.json()
  if (response.status >= 200 && response.status <= 299)
    return null
  else
    return responseJson.detail
}

function validateApiUrlPresent() {
  if (!apiRootUrl)
    throw new Error('API root URL is not set, you need to call `setApiRootUrl` before using auth service.')
}

export function setApiRootUrl(url: string) {
  apiRootUrl = url
}

export async function getAuthStatus():
Promise<[boolean, unknown | null]> {
  try {
    validateApiUrlPresent()
    const response = await fetch(`${apiRootUrl}/session/`, {
      credentials: 'include',
    })
    const data = await response.json()

    return [Boolean(data.isAuthenticated), null]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

async function getCSRFToken() {
  try {
    validateApiUrlPresent()
    const response = await fetch(`${apiRootUrl}/csrf/`, {
      credentials: 'include',
    })
    const csrfToken = response.headers.get('X-CSRFToken')
    if (csrfToken)
      return csrfToken
    else
      throw new Error('CSRF token not found in response headers.')
  }
  catch (err) {
    console.error(err)
    throw err
  }
}

export async function requestLogin(username: string, password: string):
Promise<[boolean, unknown | null]> {
  try {
    validateApiUrlPresent()
    const csrf = await getCSRFToken()
    const response = await fetch(`${apiRootUrl}/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf,
      },
      credentials: 'include',
      body: JSON.stringify({ username, password }),
    })

    const error = await validateResponse(response)
    return [error === null, error]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

export async function requestLogout(): Promise<[boolean, unknown | null]> {
  validateApiUrlPresent()
  try {
    const csrf = await getCSRFToken()
    const response = await fetch(`${apiRootUrl}/logout/`, {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrf,
      },
      credentials: 'include',
    })

    const error = await validateResponse(response)
    return [error === null, error]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

export async function requestRegister(
  password: string,
  invitationCode: string,
):
  Promise<[boolean, unknown | null]> {
  try {
    validateApiUrlPresent()
    const csrf = await getCSRFToken()
    const data = new URLSearchParams({
      password,
      invitation_code: invitationCode,
      csrfmiddlewaretoken: csrf,
    })

    const url = `${apiRootUrl}/register/`
    const response = await fetch(url, generatePostRequestInit(data))

    if (response.status >= 200 && response.status <= 299)
      return [true, null]
    else
      return [false, await response.json()]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

export async function requestStartPasswordReset(email: string):
Promise<[boolean, unknown | null]> {
  try {
    validateApiUrlPresent()
    const csrf = await getCSRFToken()
    const data = new URLSearchParams({ email, csrfmiddlewaretoken: csrf })

    const url = `${apiRootUrl}/reset-password/`
    const response = await fetch(url, generatePostRequestInit(data))

    if (response.status >= 200 && response.status <= 299)
      return [true, null]
    else
      return [false, await response.json()]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

export async function requestSetNewPassword(
  uid: string,
  password: string,
  passwordRepeated: string,
): Promise<[boolean, unknown | null]> {
  try {
    validateApiUrlPresent()
    const csrf = await getCSRFToken()
    const data = new URLSearchParams({
      new_password1: password,
      new_password2: passwordRepeated,
      csrfmiddlewaretoken: csrf,
    })

    const url = `${apiRootUrl}/reset-password/${uid}/set-password/`
    const response = await fetch(url, generatePostRequestInit(data))

    if (response.status >= 200 && response.status <= 299)
      return [true, null]
    else
      return [false, await response.json()]
  }
  catch (err) {
    console.error(err)
    return [false, err]
  }
}

function generatePostRequestInit(body: URLSearchParams): RequestInit {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json',
    },
    credentials: 'include',
    body,
  }
}
