import validate from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import rbac_45global from "/home/circleci/sleuth/frontend/middleware/rbac.global.ts";
import manifest_45route_45rule from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  rbac_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "rbac-global-generated": () => import("/home/circleci/sleuth/frontend/middleware/rbac.global.generated.ts")
}