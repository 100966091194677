import { defineNuxtPlugin } from '#app/nuxt'
import { LazyThumbnailDarkDeveloperSentimentSurvey, LazyThumbnailDarkDoraReview, LazyThumbnailDarkMonthlyCtoDirects, LazyThumbnailDarkMonthlyStateEng, LazyThumbnailDarkPlaceholder, LazyThumbnailDarkSquadHealthCheck, LazyThumbnailDarkStartFromScratch, LazyThumbnailDarkTeamSprintReview, LazyThumbnailLightDeveloperSentimentSurvey, LazyThumbnailLightDoraReview, LazyThumbnailLightMonthlyCtoDirects, LazyThumbnailLightMonthlyStateEng, LazyThumbnailLightPlaceholder, LazyThumbnailLightSquadHealthCheck, LazyThumbnailLightStartFromScratch, LazyThumbnailLightTeamSprintReview, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["ThumbnailDarkDeveloperSentimentSurvey", LazyThumbnailDarkDeveloperSentimentSurvey],
["ThumbnailDarkDoraReview", LazyThumbnailDarkDoraReview],
["ThumbnailDarkMonthlyCtoDirects", LazyThumbnailDarkMonthlyCtoDirects],
["ThumbnailDarkMonthlyStateEng", LazyThumbnailDarkMonthlyStateEng],
["ThumbnailDarkPlaceholder", LazyThumbnailDarkPlaceholder],
["ThumbnailDarkSquadHealthCheck", LazyThumbnailDarkSquadHealthCheck],
["ThumbnailDarkStartFromScratch", LazyThumbnailDarkStartFromScratch],
["ThumbnailDarkTeamSprintReview", LazyThumbnailDarkTeamSprintReview],
["ThumbnailLightDeveloperSentimentSurvey", LazyThumbnailLightDeveloperSentimentSurvey],
["ThumbnailLightDoraReview", LazyThumbnailLightDoraReview],
["ThumbnailLightMonthlyCtoDirects", LazyThumbnailLightMonthlyCtoDirects],
["ThumbnailLightMonthlyStateEng", LazyThumbnailLightMonthlyStateEng],
["ThumbnailLightPlaceholder", LazyThumbnailLightPlaceholder],
["ThumbnailLightSquadHealthCheck", LazyThumbnailLightSquadHealthCheck],
["ThumbnailLightStartFromScratch", LazyThumbnailLightStartFromScratch],
["ThumbnailLightTeamSprintReview", LazyThumbnailLightTeamSprintReview],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
