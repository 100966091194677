import { useStorage } from '@vueuse/core'
import { definePlugin } from 'villus'

export default function () {
  const lastWorkspace = useStorage('last-workspace', '')
  const toast = useToast()

  return definePlugin(({ afterQuery }) => {
    afterQuery(({ error }) => {
      if (error && error.message.match(/Workspace with id: `.*` does not exist/)) {
        lastWorkspace.value = ''
        navigateTo('/')
        toast.add({
          title: 'Workspace not found',
          description: `${error.message.slice(10)}. Redirecting to the home page.`,
          color: 'warning',
          icon: 'light:triangle-exclamation',
        })
      }
    })
  })
}
