import type * as Types from '../codegen/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetFlagStatusQueryVariables = Types.Exact<{
  flagName: Types.Scalars['String']['input'];
}>;


export type GetFlagStatusQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationType', flagEnabled: boolean } };


export const GetFlagStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFlagStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"flagName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flagEnabled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"flagName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"flagName"}}}]}]}}]}}]} as unknown as DocumentNode<GetFlagStatusQuery, GetFlagStatusQueryVariables>;