import { GetFlagStatusDocument } from './use-flag.generated'

export default function (flagName: string) {
  const { data: flagEnabled, isFetching } = useQuery({
    query: GetFlagStatusDocument,
    variables: { flagName },
  }, ({ data }) => data?.organization.flagEnabled)

  return { enabled: flagEnabled, isFetching }
}
