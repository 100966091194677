import type { WsEventType } from '~/codegen/graphql'

export interface WSEvent {
  on: string
  type: WsEventType
  objectId: string
  parentId: string
  actorId?: string
}

interface EventHandler {
  events: WsEventType[]
  matcher: (data: WSEvent) => boolean
  cb: () => void
}

const currentUserId = ref('')

export default function () {
  const { $ws, $lastWsEvents } = useNuxtApp()

  function watchAndExecute({ events, matcher, cb }: EventHandler) {
    watch($ws.data, (wsData) => {
      const data = JSON.parse(wsData)
      if (events.includes(data.type) && matcher(data)) {
        if (data.actorId && data.actorId === currentUserId.value) {
          // skip event handling triggered from the current user
          return
        }
        cb()
      }
    })
  }

  function openWs(userId: string) {
    $ws.open()
    currentUserId.value = userId
  }

  function closeWs() {
    $ws.close()
  }

  return {
    openWs,
    closeWs,
    watchAndExecute,
    status: $ws.status,
    lastWsEvents: $lastWsEvents,
  }
}
