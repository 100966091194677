import type { GetCurrentUserQuery } from './use-current-user.generated'

import { useStorage } from '@vueuse/core'
import { GetCurrentUserDocument } from './use-current-user.generated'

const onDataCount = ref(0)

export type CurrentUser = NonNullable<GetCurrentUserQuery['user']>

const user = ref<CurrentUser>()
const canChangeOrgConfig = ref<boolean>(false)
const canChangeOrg = ref<boolean>(false)

const isBeingImpersonated = useStorage('isBeingImpersonated', false)

export default function () {
  const analytics = useSegment()
  const sentrySetUser = useNuxtApp().$sentrySetUser
  const sentrySetContext = useNuxtApp().$sentrySetContext

  const { onData, execute: fetchCurrentUser, isFetching } = useQuery({
    query: GetCurrentUserDocument,
    tags: ['user'],
    fetchOnMount: false,
  }, ({ data }) => data?.user)

  onData((data) => {
    if (!data.user)
      return

    user.value = data.user
    canChangeOrg.value = data.user.permissions.canChangeOrg
    canChangeOrgConfig.value = data.user.permissions.canChangeOrgConfig
    isBeingImpersonated.value = data.user.isBeingImpersonated

    if (onDataCount.value === 0) {
      if (!data.user.isBeingImpersonated) {
        analytics.identify(data.user?.id, {
          name: data.user?.display,
          email: data.user?.email,
          organization: data.user.organizations[0].slug,
        })
      }

      sentrySetUser({
        id: data.user.id,
        email: data.user.email,
      })
      sentrySetContext('userDetails', {
        isBeingImpersonated: data.user.isBeingImpersonated,
      })
    }

    onDataCount.value += 1
  })

  return { user, canChangeOrg, canChangeOrgConfig, fetchCurrentUser, isBeingImpersonated, isFetching }
}
