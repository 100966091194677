import type { NanoPopPosition } from 'nanopop'
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { useMutationObserver } from '@vueuse/core'

export enum Tours {
  welcome = 'in-app-tour-welcome',
  review = 'in-app-tour-review',
  survey = 'in-app-tour-survey',
}

const oldRoute = ref<RouteLocationRaw | null>(null)
function rememberOldRoute(currentRoute: RouteLocationNormalized) {
  oldRoute.value = {
    name: currentRoute.name,
    params: currentRoute.params,
  } as RouteLocationRaw
}

export function waitForElement(selector: string, toBeRemoved = false): any {
  return new Promise((resolve) => {
    const el = document.querySelector(selector)
    if (!toBeRemoved && el) {
      resolve(document.querySelector(selector))
    }
    else if (toBeRemoved && !el) {
      resolve(null)
    }
    const { stop } = useMutationObserver(document.body, async () => {
      const element = document.querySelector(selector)
      if (element) {
        stop()
        resolve(element)
      }
      else if (toBeRemoved) {
        stop()
        resolve(null)
      }
    }, {
      childList: true,
      subtree: true,
    })
  })
}

async function waitForTransition(selector: string): Promise<void> {
  return new Promise((resolve) => {
    (async () => {
      const el = await waitForElement(selector)
      const listener = () => {
        el.removeEventListener('transitionend', listener)
        el.removeEventListener('transitioncancel', listener)
        resolve()
      }
      el.addEventListener('transitionend', listener)
      el.addEventListener('transitioncancel', listener)
    })()
  })
}

export function getAllTours(currentRoute: RouteLocationNormalized, canChangeOrgConfig: boolean) {
  const welcomeTour = [
    {
      target: '[data-tour="new-artifact"]',
      title: 'Let’s Get Started',
      content: `Start aligning and executing. Reviews are at the core of Sleuth as
      they bring out the right metrics at the right time. Create a review and get started today!`,
      image: '/tour-images/welcome-tour.png',
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="new-teamspace-button"]',
      title: 'Collaborate With Your Team',
      content: `Create a home for your team. Teamspaces are where you and your team
      will collaborate and create your own reviews.`,
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="org-dropdown"] button:not([data-tour="org-dropdown-button"]):first-of-type',
      title: 'Add Team Members and Data',
      content: `Manage Team Members and Global Settings. The organization
      settings area in Sleuth is where you can add users and teams, connect
      Sleuth with your existing engineering tools, and more.`,
      onBefore: async () => {
        if (currentRoute.name !== 'teamspace-workspaceId-reviews' && oldRoute.value) {
          await navigateTo(oldRoute.value)
          await waitForElement('#headlessui-portal-root', true)
        }

        const btn = await waitForElement('[data-tour="org-dropdown-button"]')
        btn.click()
        await nextTick()
        await waitForTransition('[data-tour="org-dropdown"] > div:nth-child(2)')
        await waitForElement('[data-tour="org-dropdown"] button:not([data-tour="org-dropdown-button"]):first-of-type')
        return Promise.resolve()
      },
      placement: 'left-start' as NanoPopPosition,
      permissionCheck: canChangeOrgConfig,
    },
    {
      target: '[data-tour="org-settings-nav"] li:nth-child(2)',
      title: 'Get Even Better Insights',
      content: `Gain better actionable insights by connecting Sleuth to more tools.
      By integrating Sleuth with the tools your team uses today your reviews can
      provide you with the insights you need to take action.<br /><br />
      The more integrations you add, the more insight Sleuth can provide!`,
      onBefore: async () => {
        rememberOldRoute(currentRoute)
        await navigateTo({ name: 'settings-organization-integrations' })
        await waitForElement('[data-tour="org-settings-nav"] li:nth-child(2)')
        return waitForTransition('#headlessui-portal-root')
      },
      placement: 'left-start' as NanoPopPosition,
      permissionCheck: canChangeOrgConfig,
    },
  ].filter(s => s.permissionCheck === undefined || s.permissionCheck)

  const reviewTour = [
    {
      target: '[data-tour="artifact-options-btn"]',
      title: 'Review workflow',
      content: `Enable section approvals to require review of each section before
      completion. You may also specify a future start date, which drives review notifications.`,
      image: '/tour-images/review-1.png',
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="daterangepicker-btn"]',
      title: 'Set your date range',
      content: `The date range should reflect the period under review. Metrics
      shown in the review will (by default) reflect the period selected here.`,
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="right-sidebar"]',
      title: 'Select your widgets',
      content: `Customize your review by bringing in the widget metrics you care
      about! You can use the ones provided in the template or select and modify new ones.`,
      image: '/tour-images/review-2.png',
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="right-sidebar-tabs"] > div > button:nth-child(2)',
      title: 'Review info',
      content: `Important information regarding your review is captured here.
      You can use it to see metadata about the review as well as some useful
      actions like PDF downloads and share links.`,
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="share-review-btn"]',
      title: 'Share the review',
      content: `Ready to share your review with others? The Share option gives
      you the ability to send your review to others without requiring a login to Sleuth.`,
      placement: 'left-start' as NanoPopPosition,
    },
  ]

  const surveyTour = [
    {
      target: '[data-tour="right-sidebar"]',
      title: 'Select your questions',
      content: `Customize your survey by bringing in the questions your organization
      cares about! You can use the ones provided in the template or select and modify new ones.`,
      placement: 'left-start' as NanoPopPosition,
    },
    {
      target: '[data-tour="artifact-options-dropdown"] button:not([data-tour="artifact-options-btn"]):nth-child(2)',
      title: 'Select recipients',
      content: `If no recipients are selected, survey will be sent to all members of the teamspace.`,
      placement: 'left-start' as NanoPopPosition,
      onBefore: async () => {
        const btn = await waitForElement('[data-tour="artifact-options-btn"]')
        btn.click()
        await nextTick()
        await waitForTransition('[data-tour="artifact-options-dropdown"] > div:nth-child(2)')
        await waitForElement('[data-tour="artifact-options-dropdown"] button:not([data-tour="artifact-options-btn"]):nth-child(2)')
        return Promise.resolve()
      },
    },
    {
      target: '[data-tour="publish-artifact-btn"]',
      title: 'Click start',
      content: `Starting the survey immediately sends to recipients.`,
      placement: 'left-start' as NanoPopPosition,
    },
  ]

  return {
    [Tours.welcome]: welcomeTour,
    [Tours.review]: reviewTour,
    [Tours.survey]: surveyTour,
  }
}
